import * as React from "react"
import "reset-css"
import { Layout } from "../components/layout"
import { Container, GlobalCss } from "../theme"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"
import { Feature } from "../components/feature/feature"
import styled from "styled-components"
import { ServicesCard } from "../components/services/services-card"
import { mediaQuery } from "../theme"

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  margin-top: 80px;
  justify-content: center;
  align-items: center;

  ${mediaQuery("xl")} {
    grid-template-columns: 1fr 1fr;
  }
  ${mediaQuery("lg")} {
    grid-template-columns: 1fr;
  }
`

const ServicesPage = ({ data }) => {
  const {
    page: {
      ACF_homepage: { services },
    },
  } = data

  return (
    <>
      <Seo post={data.wpPage} />
      <GlobalCss />
      <Layout>
        <Feature
          heading="Paslaugos"
          image={data.wpPage.ACF_feature.image.localFile}
          paragraph={data.wpPage.ACF_feature.text}
        />
        <Container>
          <CardsContainer>
            {services.map((service, index) => (
              <ServicesCard data={service} key={index} />
            ))}
          </CardsContainer>
        </Container>
      </Layout>
    </>
  )
}
export const query = graphql`
  query {
    wpPage(slug: { eq: "paslaugos" }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      ACF_feature {
        text
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    page: wpPage(slug: { eq: "home" }) {
      ACF_homepage {
        services {
          title
          text
          image {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default ServicesPage
